import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import styled from 'styled-components'
import Button from "../components/button"
//import Seo from "../components/seo"
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';

const DateRow = styled.div`
    margin:0 auto;
    max-width:1150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction:row;
    margin-bottom: 1rem;
    gap:1rem;
    padding:2rem 3rem ;
    background-color:${props => props.theme.colors.sandlight};
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        flex-direction:column;
        gap:1rem;
        text-align:center;
        } 
    .date { 
     min-width:130px;
      color:${props => props.theme.colors.primary};
      font-weight:bold;
    }
    .ville { 
      width:250px;
    }
    .lieu { 
      width:250px;
    }
    .links { 
      width:100px;
    }
`




const Dates = ({data}) => {
  return (
  <Layout>
       <h1 className="breadcrumb">Dates</h1>
      <div className="container maxwidth-800">
      { data.dates.edges.map(( item,i) => { 
      
      return ( 
         (new Date(item.node.date) >= new Date()) && 
      <DateRow key={i} >
        <div className="date">{format(new Date(item.node.date), 'dd LLL yyyy', {locale: fr})}</div>
        <div className="ville">{item.node.ville}</div>
        <div className="lieu">{item.node.lieu}</div>
        <div className="links"><Button external to={item.node.lien}>Réserver</Button></div>
      </DateRow>
        
      )
      })
      }
      </div>
  </Layout>
  )
}

export default Dates


export const query = graphql`
  query DateQuery {
    dates:allDatoCmsDate(sort: {order: ASC, fields: date}) {
      edges {
        node {
          date
          lieu
          ville
          lien
        }
      }
  }
}
`